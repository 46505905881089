body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.App {
    font-family: sans-serif;
    text-align: center;
}
.smallicon {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 10px; /* Adjust as needed */
    left: 10px; /* Adjust as needed */
    width: 40px; /* Adjust the width of the logo */
    height: 40px; /* Maintain aspect ratio */
}
.pict1 {
    padding-top: 12px;
    align-items: center;
    max-width: auto;
}
.Logo {
    height: 50px;
    width: 50px;
    border-bottom: 0px solid black;
    float: left;
    top: 0;
    left: 20px;
    position: absolute;
}
.Home,
.About,
.Contact,
.Projects {
    color: black;
    font-size: 30px;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    cursor: pointer;
    position: relative;
    font-size: 28px;
    border-top-right-radius: 10px;
    transition: all 1s;
}
.navigation li::after {
    border-bottom: 3px solid black;
    content: "";
    position: absolute;
    bottom: 0;
    left: 25%;
    width: 0;
    transition: width 1s ease-in-out;
}

.navigation li:hover:not(:first-child)::after {
    width: 50%;
}
.App {
    text-align: center;
}
.gridnav {
    color: #000000;
    overflow: hidden;
    height: 10vh;
    z-index: 5;
}

.gridnav nav ul {
    display: flex;
    align-items: center;
    height: 100%;
    text-align: center;
    padding: 0 100px;
}
.gridnav nav ul:not(:first-child) {
    display: flex;
    align-items: center;
    height: 100%;
    text-align: center;
    padding: 0 300px;
}
.gridnav nav ul li {
    flex: 1;
    padding: 5px 5px;
}

.gridnav nav ul li:first-child {
    flex: 0.2;
}
.gridnav nav {
    height: 100%;
}
.gridnav nav ul li:hover:not(:first-child) {
    cursor: pointer;
    position: relative;
    padding: 5px 20px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    transition: all 1s;
}
.menu-icon {
    display: none;
    cursor: pointer;
}

.menu-icon__bar {
    width: 30px;
    height: 3px;
    background-color: #333;
    margin: 6px 0;
    transition: 0.4s;
}

.menu-icon.open .menu-icon__bar:nth-child(1) {
    transform: rotate(-45deg) translate(-5px, 6px);
}

.menu-icon.open .menu-icon__bar:nth-child(2) {
    opacity: 0;
}

.menu-icon.open .menu-icon__bar:nth-child(3) {
    transform: rotate(45deg) translate(-5px, -6px);
}

/* Mobile styles */
@media screen and (max-width: 568px) {
    .smallicon {
        visibility: visible;
        opacity: 1;
    }
    .Home,
    .About,
    .Contact,
    .Projects {
        padding-top: 20px;
        color: white;
        font-family: "Montserrat", sans-serif;
        font-style: oblique;
        font-variant: small-caps;
        text-decoration: none;
        cursor: pointer;
        position: relative;
        font-size: 40px;
        border-top-right-radius: 10px;
        transition: all 1s;
    }
    .Logo {
        visibility: hidden;
        height: 30px;
    }
    .menu-icon {
        display: block;
        position: absolute;
        top: 15px;
        right: 15px;
        z-index: 2;
    }
    .smallicon {
        display: block;
    }

    .navigation li:not(:first-child):hover::after {
        width: 60%;
        align-content: center;
    }
    .navigation li:not(:first-child)::after {
        border-bottom: 2px solid white;
        content: "";
        position: absolute;
        bottom: 0;
        left: 20%;
        width: 0;
        transition: width 0.5s ease-in-out;
    }
    .navigation {
        display: none;
        position: absolute;
        top: 60px;
        background-color: #dfdfdf;
        z-index: 1;
        text-align: center;
        opacity: 0; /* Initially hidden */
    }

    .fade-in {
        animation: fadeIn 2s forwards;
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    /* Remove the slideIn animation */
    /* @keyframes slideIn {
        from {
            left: -100%;
        }
        to {
            left: 0;
        }
    } */
    .no-scroll {
        overflow: hidden;
    }
    .navigation.open {
        display: block;
        top: 60px;
        height: 100vh;
        width: 100%;
        opacity: 0; /* Start with opacity 0 */
        position: absolute;
        overflow-y: hidden;
        background-image: url("./images/navbarbg.png");
        z-index: 20;
        animation: popUp 0.5s forwards; /* Pop-up animation */
    }

    @keyframes popUp {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    .navigation ul {
        flex-direction: column;
    }
    .navigation li {
        margin-right: 0;
    }
    .gridnav nav {
        padding-top: 150px;
    }
    .gridnav nav ul {
        padding: 0px 50px;
        height: 0;
    }
    .gridnav nav ul li {
        height: 150px;
    }
    .containersecond {
        height: 40vh;
    }
}
@media screen and (min-width: 1000px) {
    .gridnav {
        color: #000000;
        overflow: hidden;
        height: 10vh;
        z-index: 5;
        position: relative;
    }
}

/* new portfolio css =============================================================================*/
