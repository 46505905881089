@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inika:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inika:wght@400;700&family=Red+Hat+Display:ital,wght@0,300..900;1,300..900&display=swap");
@import url(https://fonts.googleapis.com/css?family=Khula:700);

#loader-container {
    max-width: 220px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
@media screen and (max-width: 568px) {
    #loader-container {
        max-width: 220px;
        position: absolute;
        top: 50%;
        left: 25%;
        transform: translate(-25%, -25%);
    }
}
#loader-container path:nth-child(2) {
    stroke-dasharray: 200%;
    stroke-dashoffset: 200%;
    animation: strokeAnimate 2s 0s ease forwards;
}

#loader-container path:nth-child(3) {
    stroke-dasharray: 100%;
    stroke-dashoffset: 100%;
    animation: strokeAnimate 2s 0.3s ease forwards;
}
#loader-container path:nth-child(4) {
    stroke-dasharray: 100%;
    stroke-dashoffset: 100%;
    animation: strokeAnimate 2s 0.9s ease forwards;
}
#loader-container path:nth-child(5) {
    stroke-dasharray: 100%;
    stroke-dashoffset: 100%;
    animation: strokeAnimate 2s 1.2s ease forwards;
}
#loader-container path:nth-child(6) {
    stroke-dasharray: 100%;
    stroke-dashoffset: 100%;
    animation: strokeAnimate 2s 1.5s ease forwards;
}
#loader-container path:nth-child(7) {
    stroke-dasharray: 100%;
    stroke-dashoffset: 100%;
    animation: strokeAnimate 2s 1.8s ease forwards;
}
#loader-container path:nth-child(8) {
    stroke-dasharray: 100%;
    stroke-dashoffset: 100%;
    animation: strokeAnimate 2s 2.1s ease forwards;
}

@keyframes strokeAnimate {
    to {
        stroke-dashoffset: 0;
    }
}

@media screen and (max-width: 768px) {
    #loader-container {
        max-width: 150px;
    }
    #loader {
        width: 150px;
    }
}

@media screen and (max-width: 650px) {
    #loader-container {
        max-width: 100px;
    }
    #loader {
        width: 100px;
    }
}
.indie-flower-regular {
    font-family: "Indie Flower", cursive;
    font-weight: 400;
    font-style: normal;
}
.inika-regular {
    font-family: "Inika", serif;
    font-weight: 400;
    font-style: normal;
}
.red-hat display {
    font-family: "Red Hat Display", sans-serif;
    font-optical-sizing: auto;
}
body {
    background: #111;
}
.textundername {
    font-family: "Red Hat Display", sans-serif;
}
.hidden {
    opacity: 0;
}
.pict {
    padding: 130px;
    align-items: center;
    animation: popUpPicture 3s forwards;
}
@keyframes popUpPicture {
    from {
        opacity: 0;
        transform: translateY(100%);
    }
    to {
        opacity: 100;
        transform: translateY(0%);
    }
}
.console-container {
    font-size: 30px;
    display: block;
    color: white;
}
.console-containerabout {
    font-size: 15px;
    display: block;
    color: white;
    text-align: left;
}
.console-underscore {
    display: inline-block;
    position: relative;
    top: -0.14em;
    left: 10px;
}
.console-underscoreabout {
    font-family: Khula;
    font-size: 15px;
    display: block;
    color: black;
}
.grid4,
.grid6 {
    grid-area: grid4;
    margin: 70px 100px 0 10px;
    font-size: 35px;
    font-family: "Red Hat Display", sans-serif;
    font-style: normal;
    color: black;
    text-align: center;
}
.grid4 {
    margin-top: 30%;
    grid-area: grid4;
    animation: popUp 3s forwards;
}
.grid5 {
    grid-area: grid5;
}
.grid6 {
    grid-area: grid6;
    animation: popUp 4s forwards;
}

.grid1 {
    grid-area: grid1;
    margin: 200px 0 0 100px;
    height: 100px;
    animation: popUp 2s forwards;
}
.pict {
}
@keyframes popUp {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
.grid9 {
    grid-area: grid9;
}
.name {
    color: #111;
    font-family: "Red Hat Display", sans-serif;
    font-weight: 400;
    font-size: 70px;
}
.sociallinks {
    display: grid;
}
/* button {
    border-radius: 4px;
    background-color: white;
    border: none;
    color: #fff;
    text-align: center;




    font-size: 32px;
    padding: 16px;
    width: 250px;
    transition: all 0.5s;
    cursor: pointer;
    margin: 36px;
    box-shadow: 0 10px 20px -8px rgba(0, 0, 0, 0.7);
}

button {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
}

button:after {
    content: "»";
    position: absolute;
    opacity: 0;
    top: 0px;
    right: -20px;
    transition: 0.5s;
}

button:hover {
    padding-right: 24px;
    padding-left: 8px;
}

button:hover:after {
    opacity: 1;
    transition: 0.5s;
    right: 10px;
} */
/* 
.containerhome {
    font-size: 70px;
    font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
    color: hsl(0, 0%, 0%); */
/* text-align: right; */
/* width: 700px; */
/* height: 90vh;

    grid-template-areas:
        "grid1 grid5 grid4"
        "grid1 grid5 grid6"
        "grid1 grid5 grid9";
    grid-template-columns: 1fr 1.05fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;

    background: linear-gradient(180deg, #f0ffff, #838b8b, #4b5320);
    background-size: 200% 600%;
    color: rgb(236, 236, 236);
    display: grid;
}
.motherdivsocial {
    padding-top: 3;
    padding-bottom: 16;
}

@media screen and (max-width: 568px) {
    .grid4,
    .grid6 {
        margin-right: 20px;
        font-size: 27px;
    }
    .grid5 {
        margin-right: 30px;
    }
    .pict {
        height: 520px;
        width: 240px;
        transition: transform 0.3s;
        padding: 0;
        margin-right: 10px;
        overflow-y: hidden;
        animation: popUpPicture 2s forwards;
    }

    @keyframes popUpPicture {
        from {
            opacity: 0;
            transform: translateX(100%);
        }
        to {
            opacity: 100;
            transform: translateX(0%);
        }
    }
    .grid1 {
        margin: 10px 0px 0 0px;
        text-align: center;
        align-items: center;
        justify-content: center;
        justify-items: center;
        float: left;
        position: relative;
    }
    .grid4 {
        font-size: 20px;
        margin-top: 40px;
    }
    .console-container {
        font-family: Khula;
        font-size: 30px;
        display: block;
        color: white;
    } */
/* .console-underscore {
        display: inline-block;
        position: relative;
        top: 0;
        left: 10px;
    }
    .name {
        color: #111;
        font-family: Khula;
        font-size: 40px;
    } */
/* button {
        width: 230px;
        transition: all 0.5s;
        cursor: pointer;
        box-shadow: 0 10px 20px -8px rgba(0, 0, 0, 0.7);
        margin-left: 5px;
        margin-top: 0px;
    } */
/* .containerhome {
        color: hsl(0, 0%, 0%);
        height: 40%;
        grid-template-areas:
            "grid1 grid5"
            "grid4 grid5"
            "grid6 grid5";
        grid-template-columns: 230px 230px;
        grid-template-rows: 150px 190px 0.1fr;
        background-size: 200% 600%;
        color: rgb(236, 236, 236);
        display: grid;
        overflow-x: hidden;
        overflow-y: hidden;
    }
    .sociallinks {
        visibility: hidden;
        overflow-y: hidden;
    }
    .motherdivsocial {
        padding-top: 0;
        padding-bottom: 0;
        overflow-y: hidden;
        height: 1vh;
        overflow-y: hidden;
    }
    .iconsforsocial {
        margin-top: 0;
        overflow-y: hidden;
    }
    .logosocial {
        visibility: hidden;
        width: 0;
        height: 0;
        opacity: 0;
    }
    .containerhome {
        height: 60vh;
        overflow-y: hidden;
    }
    .textforsocial {
        font-size: 14px;
    }
}
@media screen and (min-width: 569px) and (max-width: 700px) {
    .grid1 {
        margin: 100px 0 0 50px;
        height: 80px;
    }

    .grid4,
    .grid6 {
        margin: 50px 50px 0 10px;
        font-size: 30px;
    }

    .grid5 {
        display: none;
    }

    .name {
        font-size: 50px;
    } */

/* button {
        width: 200px;
        font-size: 24px;
        padding: 12px;
        margin: 24px;
    } */
/* 
    .containerhome {
        height: 80vh;
        grid-template-areas:
            "grid1 grid4 grid5"
            "grid1 grid6 grid9";
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr;
    }
}

@media screen and (min-width: 701px) and (max-width: 967px) {
    .grid1 {
        margin: 150px 0 0 100px;
        height: 120px;
    }

    .grid4,
    .grid6 {
        margin: 70px 100px 0 10px;
        font-size: 35px;
    }

    .grid5 {
        display: block;
    }

    .name {
        font-size: 60px;
    }

    button {
        width: 250px;
        font-size: 28px;
        padding: 14px;
        margin: 30px;
    }

    .containerhome {
        height: 85vh;
    }
}

@media screen and (min-width: 968px) and (max-width: 1235px) {
    .grid1 {
        margin: 200px 0 0 100px;
        height: 150px;
    }

    .grid4,
    .grid6 {
        margin: 70px 100px 0 10px;
        font-size: 40px;
    }

    .grid5 {
        display: block;
    }

    .name {
        font-size: 70px;
    }

    button {
        width: 300px;
        font-size: 32px;
        padding: 16px;
        margin: 36px;
    }

    .containerhome {
        height: 90vh;
    }
}
@media screen and (min-width: 1820px) {
    .containerhome {
        height: 100vh;
    }
} */
/*==========================CONTACT CSS====================================*/
/* .formbackground {
    isolation: isolation;
    background-color: #997d65;
    padding: 40px 24px;
}
.contactgrid1 {
    grid-area: contactgrid1;
    height: 300px;
    width: 1481px;
    justify-self: center;
    position: absolute;
    display: block;
    top: 0;
    z-index: 1;
    object-fit: cover;
    width: 100%;
    height: 100%;
}
.contactgrid1 img {
    object-fit: cover;
    height: 100%;
    width: 100%;
}
.contactgrid1-second {
    grid-area: contactgrid1-second;
    font-family: "Red Hat Display", sans-serif;
    margin-left: 20px;
    margin-top: 15px;
    font-size: 61px;
    justify-self: center;
    float: top;
    color: white;
}
.contactgrid2-second {
    grid-area: contactgrid2-second;
}
.contactgrid3-second {
    grid-area: contactgrid3-second;
    margin-bottom: 10px;
}
.contactgrid2-second,
.contactgrid3-second {
    font-family: "Red Hat Display", sans-serif;
    font-size: 30px;
    margin-top: 10px;
    color: white;
    text-align: center;
}
.containerfirstContact {
    font-size: 70px;
    font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
    color: hsl(0, 0%, 0%);
    height: 90vh;
    grid-template-areas: "contactgrid1";
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    display: grid;
    z-index: 1;
}
.containersecondContact {
    font-size: 70px;
    font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
    color: hsl(0, 0%, 0%);
    height: 30vh;
    background-color: #432e36;
    grid-template-areas:
        ". contactgrid1-second . "
        ". contactgrid2-second . "
        ". contactgrid3-second . ";
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    display: grid;
}
@media (max-width: 568px) {
    .contactgrid1 {
        grid-area: contactgrid1;
        height: 20vh;
        width: 100%;
        justify-self: center;
        position: absolute;
        display: block;
        top: 0;
        z-index: 1;
    }

    .contactgrid1 img {
        max-width: 100%;
        height: auto;
    }

    .contactgrid1-second {
        grid-area: contactgrid1-second;
        font-family: "Red Hat Display", sans-serif;
        margin: 0;
        font-size: 27px;
        text-align: center;
        color: white;
    }

    .contactgrid2-second {
        grid-area: contactgrid2-second;
        font-family: "Red Hat Display", sans-serif;
        font-size: 14px;
        margin: 0;
        color: white;
        text-align: center;
    }
    .contactgrid3-second {
        grid-area: contactgrid3-second;
        font-family: "Red Hat Display", sans-serif;
        font-size: 14px;
        margin: 0;
        color: white;
        text-align: center;
    }

    .containerfirstContact {
        font-size: 2rem;
        font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
        color: hsl(0, 0%, 0%);
        height: 12vh;
        grid-template-areas: "contactgrid1";
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        display: grid;
        z-index: 1;
    }

    .containersecondContact {
        font-size: 2rem;
        font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
        color: hsl(0, 0%, 0%);
        height: 100px;
        background-color: #432e36;
        grid-template-areas:
            ". . ."
            "contactgrid1-second contactgrid2-second contactgrid2-second"
            "contactgrid1-second contactgrid3-second contactgrid3-second"
            ". contactgrid3-second contactgrid3-second";
        grid-template-columns: 1fr 1fr 0.1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr;
        display: grid;
    }
} */
/*===========================About Page========================= Mobile first approach =============*/
/* .grid1url {
    grid-area: grid1url;
    height: 22px;
    width: 22px;
    margin-top: 6px;
}
.grid2url {
    grid-area: grid2url;
    height: 20px;
    width: 20px;
    margin-top: 6px;
}
.grid3url {
    background-color: #4b505b;
    height: 30px;
    border-radius: 6px;
    justify-content: center;
    margin-top: 5px;
}
.grid4url {
    height: 40px;
    width: 40px;
    grid-area: grid4url;
}
.grid3url-text {
    margin-left: 10px;
    color: white;
}
.exiturl {
    height: 30px;
    width: 40px;
    margin: 5px 0 0 5px;
}
.containerfirsturl {
    display: grid;
    background-color: #1f2229;
    height: 6vh;
    grid-template-areas: "grid1url grid2url grid3url grid4url";
    grid-template-columns: 0.1fr 0.1fr 1fr 0.2fr;
}
.grid1about-first {
    color: #f34646;
    font-size: 40px;
    grid-area: grid1about-first;
    text-align: end;
    margin-top: 10px;
}
.grid2about-first {
    color: blue;
    font-size: 100px;
    grid-area: grid2about-first;
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: -50px;
    font-family: "Product Sans";
}
.grid2about-firstsections {
    color: blue;
    font-size: 60px;
    grid-area: grid2about-first;
    display: flex;
    justify-content: center;
    text-align: center;
    font-family: "Product Sans";
}
.grid3about-first {
    color: #597ee6;
    font-size: 40px;
    grid-area: grid3about-first;
    margin-top: -50px;
}
.letterB,
.lettera {
    color: #4673f3;
}
.letterr,
.lettert {
    color: #f34646;
}
.lettery {
    color: #eef346;
}
.lettern {
    color: #46f346;
}
.containerfirstabout {
    display: grid;
    background-color: #f4feff;
    grid-template-areas:
        "grid1about-first . ."
        "grid2about-first grid2about-first grid2about-first"
        ". . grid3about-first";
    grid-template-columns: 0.8fr 0.1fr 0.8fr;
    grid-template-rows: 0.1fr 0.1fr 0.1fr;
}
.searchbar {
    background-color: #f4feff;
    border-radius: 5px;
    border-color: #0000ff;
    border-width: 2px;
    width: 60%;
    text-align: center;
    height: 30px;
}
.skillsdiv {
    background-color: #f4feff;
}
.searchbarbox {
    display: flex;
    justify-content: center;
}
.containersecondabout {
    background-color: #f4feff;
}
.skillsbuttondiv {
    grid-area: skillsbuttondiv;
    background-color: #e4e4e4;
    height: 100%;
    width: 90%;
    text-align: center;
    border-radius: 5px;
    margin-top: 10px;
    font-size: 20px;
}
.hobbiesbuttondiv {
    grid-area: hobbiesbuttondiv;
    background-color: #e4e4e4;
    height: 100%;
    width: 90%;
    text-align: center;
    border-radius: 5px;
    margin-top: 10px;
    justify-self: end;
    font-size: 20px;
}
.cvbuttondiv {
    grid-area: whobuttondiv;
    background-color: #e4e4e4;
    height: 80%;
    width: 90%;
    text-align: center;
    border-radius: 5px;
    margin-top: 20px;
    font-size: 20px;
}
.whobuttondiv {
    grid-area: cvbuttondiv;
    background-color: #e4e4e4;
    height: 80%;
    width: 90%;
    text-align: center;
    border-radius: 5px;
    margin-top: 20px;
    justify-self: end;
    font-size: 20px;
}
.containerthirdabout {
    display: grid;
    height: 12vh;
    background-color: #f4feff;
    grid-template-areas:
        ". skillsbuttondiv hobbiesbuttondiv hobbiesbuttondiv ."
        ". whobuttondiv whobuttondiv cvbuttondiv .";
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}
.arrow {
    grid-area: arrow;
}
.containerfourthabout {
    height: 50vh;
    background-color: #f4feff;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas:
        ". . ."
        ". . ."
        ". arrow .";
}
.grid1-fifthabout {
    grid-area: grid1-fifthabout;
}
.grid2-fifthabout {
    grid-area: grid2-fifthabout;
}

.containerfifthabout {
    display: grid;
    height: 100%;
    background-color: #f4feff;
}
.searchbarabout {
    grid-area: searchbarabout;
    background-color: #f4feff;
    height: 40px;
    margin-top: 5px;
    border-radius: 5px;
    box-shadow: 3px 3px 5px black;
    box-sizing: 5px;
    margin-bottom: 10px;
    margin-right: 5px;
}
.searchbarsections {
    display: grid;
    grid-template-areas: "grid2about-first searchbarabout searchbarabout";
    grid-template-columns: 0.5fr 1fr 1fr 0.3fr;
}
.skillsboxone {
    grid-area: skillsboxone;
    background-color: #000000;
    height: auto;
    width: 50px;
}
.skillsboxes {
    display: grid;
}
.containerresults {
    grid-area: containerresults;
}
.searchedresults {
    grid-area: searchedresults;
}
@media screen and (min-width: 600px) {
    br {
        content: "";
        margin: 2em;
        display: block;
        font-size: 30px;
    }
    .searchresults {
        display: grid;
        grid-template-areas:
            ". containerresults ."
            ". searchedresults .";
        grid-template-columns: 0.4fr 1fr 1fr;
    }
    .skillsbargraph {
        grid-area: skillsbargraph;
    }
    .skillstext {
        grid-area: skillstext;
    }
    .skillsfunfact {
        grid-area: skillsfunfact;
    }
    .skillsside {
        display: grid;
        height: 100%;
        background-color: #f4feff;
        grid-template-areas:
            "grid1-fifthabout grid1-fifthabout"
            "skillsbargraph skillstext"
            ". ."
            "skillsfunfact skillsfunfact";
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 0.4fr 1fr;
    }
    .firstpainting {
        grid-area: firstpainting;
    }
    .secondpainting {
        grid-area: secondpainting;
    }
    .thirdpainting {
        grid-area: thirdpainting;
    }
    .paintingcontainer {
        margin-top: 30px;
        display: grid;
        grid-template-areas: "firstpainting secondpainting thirdpainting";
        grid-template-columns: 1fr 1fr 1fr;
    }

    .musicdiv {
        grid-area: musicdiv;
    }
    .videogamediv {
        grid-area: videogamediv;
    }
    .dancediv {
        grid-area: dancediv;
    }
    .sportsdiv {
        grid-area: sportsdiv;
    }
    .bottomart {
        display: grid;
        grid-template-areas: "musicdiv videogamediv dancediv sportsdiv";
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    .childhooddiv {
        grid-area: childhooddiv;
    }
    .happeningdiv {
        grid-area: happeningdiv;
    }
    .childhoodandhappening {
        display: grid;
        grid-template-areas: "childhooddiv happeningdiv";
    }
} */

/*================SKILLS PAGE===================*/
/* .containerfirstskills {
    height: 100vh;
    background-color: red;
} */
/*================PROJECTS PAGE================*/
/*
.grid1-projects {
    grid-area: grid1-projects;
    background-color: white;
    text-align: center;
    font-family: "Red Hat Display", sans-serif;
    font-size: 30px;
}
.containerfirstprojects {
    display: grid;
    background-color: white;

    grid-template-areas: ". grid1-projects .";
    grid-template-columns: 1fr 1fr 1fr;
}
*/
/*
.grid1-projects-box6,
.grid1-projects-box5,
.grid1-projects-box4,
.grid1-projects-box3,
.grid1-projects-box2,
.grid1-projects-box1 {
    display: grid;
    height: 250px;
    width: 250px;
    border-radius: 10px;
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 40px;
    margin-bottom: 40px;
    text-align: center;
    justify-self: center;
}
.grid1-projects-box1 {
    grid-area: grid1-projects-box1;
}
.grid1-projects-box2 {
    grid-area: grid1-projects-box2;
}
.grid1-projects-box3 {
    grid-area: grid1-projects-box3;
}
.grid1-projects-box4 {
    grid-area: grid1-projects-box4;
}
.grid1-projects-box5 {
    grid-area: grid1-projects-box5;
}
.grid1-projects-box6 {
    grid-area: grid1-projects-box6;
}

.containersecondprojects {
    display: grid;
    background-color: white;
    height: 100%;
    grid-template-areas:
        "grid1-projects-box1 grid1-projects-box2 grid1-projects-box3"
        "grid1-projects-box4 grid1-projects-box5 grid1-projects-box6";
    grid-template-columns: 1fr 1fr 1fr;
    margin-left: 50px;
    margin-right: 50px;
}
.card {
    width: 300px;
    height: 200px;
    perspective: 1000px;
}

.card-inner {
    width: 100%;
    height: 100%;
    position: relative;
    transform-style: preserve-3d;
    transition: transform 0.999s;
}

.card:hover .card-inner {
    transform: rotateY(180deg);
}

.card-front,
.card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
}

.card-front {
    color: #fff;
    display: flex;
    align-items: center;
    border-radius: 10px;
    justify-content: center;
    font-size: 24px;
    transform: rotateY(0deg);
}

.card-back {
    background-color: #000000;
    color: #fff;
    display: flex;
    align-items: center;
    border-radius: 10px;
    justify-content: center;
    font-size: 24px;
    transform: rotateY(180deg);
}
@media screen and (max-width: 568px) {
    .grid1url {
        grid-area: grid1url;
        height: 22px;
        width: 22px;
        margin-top: 6px;
    }
    .grid2url {
        grid-area: grid2url;
        height: 20px;
        width: 20px;
        margin-top: 6px;
    }
    .grid3url {
        background-color: #4b505b;
        height: 30px;
        border-radius: 6px;
        justify-content: center;
        margin-top: 5px;
    }
    .grid4url {
        height: 40px;
        width: 40px;
        grid-area: grid4url;
    }
    .grid3url-text {
        margin-left: 10px;
        color: white;
    }
    .exiturl {
        height: 30px;
        width: 40px;
        margin: 5px 0 0 5px;
    }
    .containerfirsturl {
        display: grid;
        background-color: #1f2229;
        height: 6vh;
        grid-template-areas: "grid1url grid2url grid3url grid4url";
        grid-template-columns: 0.1fr 0.1fr 1fr 0.2fr;
    }
    .grid1about-first {
        color: #f34646;
        font-size: 20px;
        grid-area: grid1about-first;
        text-align: end;
        margin-top: 10px;
    }
    .grid2about-first {
        color: blue;
        font-size: 60px;
        grid-area: grid2about-first;
        display: flex;
        justify-content: center;
        text-align: center;
        margin-top: -25px;
        font-family: "Product Sans";
    }
    .grid2about-firstsections {
        color: blue;
        font-size: 60px;
        grid-area: grid2about-first;
        display: flex;
        justify-content: center;
        text-align: center;
        font-family: "Product Sans";
    }
    .grid3about-first {
        color: #597ee6;
        font-size: 20px;
        grid-area: grid3about-first;
        margin-top: -25px;
    }
    .letterB,
    .lettera {
        color: #4673f3;
    }
    .letterr,
    .lettert {
        color: #f34646;
    }
    .lettery {
        color: #eef346;
    }
    .lettern {
        color: #46f346;
    }
    .containerfirstabout {
        display: grid;
        background-color: #f4feff;
        grid-template-areas:
            "grid1about-first . ."
            "grid2about-first grid2about-first grid2about-first"
            ". . grid3about-first";
        grid-template-columns: 0.8fr 0.2fr 0.8fr;
        grid-template-rows: 0.1fr 0.1fr 0.1fr;
    }
    .searchbar {
        background-color: #f4feff;
        border-radius: 5px;
        border-color: #0000ff;
        border-width: 2px;
        width: 80%;
        text-align: center;
    }
    .skillsdiv {
        background-color: #f4feff;
    }
    .searchbarbox {
        display: flex;
        justify-content: center;
    }
    .containersecondabout {
        background-color: #f4feff;
    }
    .skillsbuttondiv {
        grid-area: skillsbuttondiv;
        background-color: #e4e4e4;
        height: 100%;
        width: 90%;
        text-align: center;
        border-radius: 5px;
        margin-top: 10px;
        font-size: 15px;
    }
    .hobbiesbuttondiv {
        grid-area: hobbiesbuttondiv;
        background-color: #e4e4e4;
        height: 100%;
        width: 90%;
        text-align: center;
        border-radius: 5px;
        margin-top: 10px;
        justify-self: end;
        font-size: 15px;
    }
    .cvbuttondiv {
        grid-area: whobuttondiv;
        background-color: #e4e4e4;
        height: 30px;

        width: 90%;
        text-align: center;
        border-radius: 5px;
        margin-top: 20px;
        font-size: 15px;
    }
    .whobuttondiv {
        grid-area: cvbuttondiv;
        background-color: #e4e4e4;
        height: 30px;
        width: 90%;
        text-align: center;
        border-radius: 5px;
        margin-top: 20px;
        justify-self: end;
        font-size: 15px;
    }
    .containerthirdabout {
        display: grid;
        height: 12vh;
        background-color: #f4feff;
        grid-template-areas:
            ". skillsbuttondiv hobbiesbuttondiv hobbiesbuttondiv ."
            ". whobuttondiv whobuttondiv cvbuttondiv .";
        grid-template-columns: 0.4fr 1fr 1fr 1fr 0.4fr;
    }
    .arrow {
        grid-area: arrow;
    }
    .containerfourthabout {
        display: grid;
        grid-template-rows: 1fr 1fr 1fr;
        grid-template-areas:
            ""
            ""
            "arrow";
        height: 50vh;
        background-color: #f4feff;
    }
    .grid1-fifthabout {
        grid-area: grid1-fifthabout;
    }
    .grid2-fifthabout {
        grid-area: grid2-fifthabout;
    }

    .containerfifthabout {
        display: grid;
        height: 100%;
        background-color: #f4feff;
    }
    .searchbarabout {
        grid-area: searchbarabout;
        background-color: #f4feff;
        height: 30px;
        margin-top: 5px;
        border-radius: 5px;
        box-shadow: 3px 3px 5px black;
        box-sizing: 5px;
        margin-bottom: 10px;
        margin-right: 5px;
    }
    .searchbarsections {
        display: grid;
        grid-template-areas: "grid2about-first searchbarabout searchbarabout";
        grid-template-columns: 1fr 1fr 1fr;
    }
    .skillsboxone {
        grid-area: skillsboxone;
        background-color: #000000;
        height: auto;
        width: 50px;
    }
    .skillsboxes {
        display: grid;
    }
}
/*================SKILLS PAGE===================*/
/*
.containerfirstskills {
    height: 100vh;
    background-color: red;
}
/*================PROJECTS PAGE================*/
/*
.grid1-projects {
    grid-area: grid1-projects;
    background-color: white;
    text-align: center;
    font-family: "Red Hat Display", sans-serif;
    font-size: 30px;
}
.containerfirstprojects {
    display: grid;
    background-color: white;

    grid-template-areas: ". grid1-projects .";
    grid-template-columns: 1fr 1fr 1fr;
}
.grid1-projects-box6,
.grid1-projects-box5,
.grid1-projects-box4,
.grid1-projects-box3,
.grid1-projects-box2,
.grid1-projects-box1 {
    display: grid;
    height: 250px;
    width: 250px;
    border-radius: 10px;
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 40px;
    margin-bottom: 40px;
    text-align: center;
    justify-self: center;
}
*/
/*=========================================================================================
.grid1-projects-box1 {
    grid-area: grid1-projects-box1;
}
.grid1-projects-box2 {
    grid-area: grid1-projects-box2;
}
.grid1-projects-box3 {
    grid-area: grid1-projects-box3;
}
.grid1-projects-box4 {
    grid-area: grid1-projects-box4;
}
.grid1-projects-box5 {
    grid-area: grid1-projects-box5;
}
.grid1-projects-box6 {
    grid-area: grid1-projects-box6;
}

.containersecondprojects {
    display: grid;
    background-color: white;
    height: 100%;
    grid-template-areas:
        "grid1-projects-box1 grid1-projects-box2 grid1-projects-box3"
        "grid1-projects-box4 grid1-projects-box5 grid1-projects-box6";
    grid-template-columns: 1fr 1fr 1fr;
    margin-left: 50px;
    margin-right: 50px;
}
.card {
    width: 300px;
    height: 200px;
    perspective: 1000px;
}

.card-inner {
    width: 100%;
    height: 100%;
    position: relative;
    transform-style: preserve-3d;
    transition: transform 0.999s;
}

.card:hover .card-inner {
    transform: rotateY(180deg);
}

.card-front,
.card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
}

.card-front {
    color: #fff;
    display: flex;
    align-items: center;
    border-radius: 10px;
    justify-content: center;
    font-size: 24px;
    transform: rotateY(0deg);
}

.card-back {
    background-color: #000000;
    color: #fff;
    display: flex;
    align-items: center;
    border-radius: 10px;
    justify-content: center;
    font-size: 24px;
    transform: rotateY(180deg);
}
@media screen and (max-width: 568px) {
    .containersecondprojects {
        display: grid;
        background-color: white;
        height: 100%;
        grid-template-areas:
            "grid1-projects-box1"
            "grid1-projects-box2"
            "grid1-projects-box3"
            "grid1-projects-box4"
            "grid1-projects-box5"
            "grid1-projects-box6";
        grid-template-columns: 1fr;
        margin-left: 1px;
        margin-right: 50px;
    }
    .grid1-projects-box6,
    .grid1-projects-box5,
    .grid1-projects-box4,
    .grid1-projects-box3,
    .grid1-projects-box2,
    .grid1-projects-box1 {
        display: grid;
        height: 250px;
        width: 250px;
        border-radius: 10px;
        margin-left: 30px;
        margin-right: 30px;
        margin-top: 40px;
        margin-bottom: 40px;
        text-align: center;
        justify-self: center;
    }
}
===============================================================================*/
/* new home css =============================================================================== */
