@tailwind base;
@tailwind components;
@tailwind utilities;

/* .navigations {
    position: relative;
}
.navmobilebg {
    background-color: #edd3c4;
    opacity: 0.9;
    text-align: center;
}
.About {
    position: relative;
    left: 30vw;
    width: 50%;
}
.Contact {
    position: relative;
    left: 20vw;
}
.Project {
    position: relative;
    left: 30vw;
}
.newHomeLink {
    grid-area: newHomeLink;
    font-family: "Unica One", sans-serif;
    font-size: 1.5vw;
}
.newAboutLink {
    grid-area: newAboutLink;
    font-family: "Unica One", sans-serif;
    font-size: 1.5vw;
}
.newContactLink {
    grid-area: newContactLink;
    font-family: "Unica One", sans-serif;
    font-size: 1.5vw;
    text-align: left;
}
.newProjectsLink {
    grid-area: newProjectsLink;
    font-family: "Unica One", sans-serif;
    font-size: 1.5vw;
    text-align: left;
}
.gridNav {
    background-color: #edd3c4;
}
.newLogo {
    grid-area: newLogo;
    position: relative;
    height: 5vw;
    width: auto;
    margin-left: 1vw;
}
.newNav {
    display: grid;
    grid-template-columns: 0.3fr 1fr 0.2fr 0.2fr 0.2fr 0.2fr;
    grid-template-areas: "newLogo . newHomeLink newAboutLink newContactLink newProjectsLink";
    background-color: #edd3c4;
    height: 8vh;
    align-items: center;
    padding: 0 20px;
}
.newNav a {
    color: #5c4033;
    text-decoration: none;
    font-size: 1.2em;
}
.newNav a:hover {
    text-decoration: underline;
} */
.newHomeLink,
.newAboutLink,
.newContactLink,
.newProjectsLink {
    font-family: "Unica One", sans-serif;
    font-size: 2em;
}
.newLogo {
    position: relative;
    grid-area: newLogo;
    height: 4em;
    width: auto;
    top: 1em;
}
.newHomeLink {
    grid-area: newHomeLink;
}
.newAboutLink {
    grid-area: newAboutLink;
}
.newContactLink {
    grid-area: newContactLink;
}
.newProjectsLink {
    grid-area: newProjectsLink;
}
.newNav {
    background-color: #edd3c4;
}
/* HOME CSS======================================================================= */
.newHomeGrid1 {
    grid-area: newHomeGrid1;
}
.newPortPic1 {
    grid-area: newPortPic1;
    height: 20em; /* Responsive image size */
    width: 20em;
    border-radius: 50%;
    margin: 0;
    position: relative;
}
.newInHomeText {
    grid-area: newInHomeText;
    font-family: "Unica One", sans-serif;
    position: relative;
    top: 50px;
    font-size: 3vw;
    font-style: inherit;
    top: 8vw;
    text-align: center;
    margin-right: 10px;
    left: 1.6rem;
    color: white;
    text-align: left;
}
.nameInHome {
    grid-area: nameInHome;
    font-family: "Unica One", sans-serif;
    font-size: 10rem;
    color: #550000;
    position: relative;
    top: 80px;
}
.textInHome {
    grid-area: textInHome;
}
.portfolioInHomeFirst {
    grid-area: portfolioInHomeFirst;
    align-self: start;
    position: relative;
    top: -90px;
}
.portfolioInHomeSecond {
    grid-area: portfolioInHomeSecond;
    position: relative;
    top: -90px;
}
.portftext {
    font-family: "Unica One", sans-serif;
    font-size: 20em;
    position: relative;
    bottom: 0.2em;
}
.liotext {
    font-family: "Unica One", sans-serif;
    font-size: 20em;
    position: relative;
    bottom: 0.2em;
}
.newAboutMeButton {
    grid-area: newAboutMeButton;
    background-color: #ffffff;
    font-family: "Unica One", sans-serif;
    font-size: 2rem;
    border-radius: 10rem;
    width: 70%;
    float: top;
    position: relative;
    bottom: 8rem;
    box-shadow: 12px 12px 2px 1px rgb(0, 0, 0);
}
.newCVButton {
    grid-area: newCVButton;
    background-color: #ffffff;
    font-family: "Unica One", sans-serif;
    font-size: 2rem;
    border-radius: 10rem;
    width: 30%;
    float: top;
    position: relative;
    bottom: 8rem;
    box-shadow: 12px 12px 2px 1px rgb(0, 0, 0);
}
.newProjectsButton {
    grid-area: newProjectsButton;
    background-color: #ffffff;
    font-family: "Unica One", sans-serif;
    font-size: 2rem;
    border-radius: 10rem;
    width: 70%;
    float: top;
    position: relative;
    bottom: 8rem;
    right: 6rem;
    box-shadow: 12px 12px 2px 1px rgb(0, 0, 0);
}
.linkedinicon {
    grid-area: linkedinicon;
    position: relative;
    bottom: 7rem;
    left: 11rem;
    box-shadow: 6px 6px 2px 1px rgb(0, 0, 0);
    border-radius: 4rem;
}
.facebookicon {
    grid-area: facebookicon;
    position: relative;
    bottom: 7rem;
    left: 14rem;
    box-shadow: 6px 6px 2px 1px rgb(0, 0, 0);
    border-radius: 4rem;
}
.githubicon {
    grid-area: githubicon;
    position: relative;
    bottom: 7rem;
    left: 1rem;
    box-shadow: 6px 6px 2px 1px rgb(0, 0, 0);
    border-radius: 4rem;
}
.instagramicon {
    grid-area: instagramicon;
    position: relative;
    bottom: 7rem;
    right: 9rem;
    box-shadow: 6px 6px 2px 1px rgb(0, 0, 0);
    border-radius: 4rem;
}
.discordicon {
    grid-area: discordicon;
    position: relative;
    bottom: 7rem;
    right: 18rem;
    box-shadow: 6px 6px 2px 1px rgb(0, 0, 0);
    border-radius: 4rem;
}
.newtextundername {
    grid-area: newtextundername;
    font-family: "Unica One", sans-serif;
    font-size: 2vw;
}
.newHomeMotherDiv {
    display: grid;
    grid-template-rows: 0.4fr 0.3fr 1fr 1fr 1fr 1fr 0.1fr;
    grid-template-columns: 0.1fr 1fr 0.1fr 1fr 0.9fr 0.8fr 0.7fr 0.1fr;
    grid-template-areas:
        ". newHomeGrid1 newHomeGrid1 . . newInHomeText newInHomeText ."
        ". . . . . newtextundername newtextundername ."
        ". textInHome textInHome textInHome textInHome textInHome textInHome ."
        ". . . newAboutMeButton newCVButton newProjectsButton . ."
        ". . . facebookicon githubicon instagramicon . .";
    background-color: #d1603d;
    height: 92.5vh;
}

/* @media only screen and (min-width: 481px) and (max-width: 768px) {
    .newHomeMotherDiv {
        display: grid;
        grid-template-rows: 0.1fr 0.4fr 0.3fr 1fr 1fr 1fr 1fr 0.1fr;
        grid-template-columns: 0.1fr 1fr 0.1fr 1fr 0.9fr 0.8fr 0.7fr 0.1fr;
        grid-template-areas:
            ". . . . . . . ."
            ". newHomeGrid1 newHomeGrid1 . . newInHomeText newInHomeText ."
            ". . . . . newtextundername newtextundername ."
            ". portfolioInHomeFirst portfolioInHomeFirst portfolioInHomeFirst newPortPic1 portfolioInHomeSecond portfolioInHomeSecond ."
            ". . . newAboutMeButton newCVButton newProjectsButton . ."
            ". . linkedinicon facebookicon githubicon instagramicon discordicon .";
        height: 92vh;
    }
} */
/* new about me ============================================================================================================================= */
.newAboutFirstText {
    grid-area: newAboutFirstText;
    font-family: "Unica One", sans-serif;
    font-size: 2vw;
    color: #ffffff;
    text-align: center;
    margin: 0vw 20vw 0vw 20vw;
    position: relative;
    top: 4vw;
}
.newAboutSecondText {
    grid-area: newAboutSecondText;
    font-family: "Unica One", sans-serif;
    font-size: 2vw;
    color: #ffffff;
    text-align: center;
    margin: 0vw 20vw 0vw 20vw;
    position: relative;
    top: 2vw;
}
.newJSText {
    grid-area: newJSText;
    font-family: "Unica One", sans-serif;
    font-size: 3vw;
    color: #ffffff;
    text-align: center;
    position: relative;
    top: 8vw;
}
.dogpeeking {
    grid-area: dogpeeking;
    position: relative;
    left: 15vw;
    bottom: -2vw;
}
.catpeeking {
    grid-area: catpeeking;
    position: relative;
    right: 13vw;
    bottom: -4vw;
    height: 15vw;
}
.newAboutMeFirstPage {
    display: grid;
    grid-area: newAboutMeFirstPage;
    grid-template-rows: 0.3fr 1fr 1fr 0.3fr;
    grid-template-columns: 0.3fr 1fr 0.3fr;
    grid-template-areas:
        ". . ."
        "newAboutFirstText newAboutFirstText newAboutFirstText"
        "newAboutSecondText newAboutSecondText newAboutSecondText"
        "dogpeeking newJSText catpeeking"
        "dogpeeking . catpeeking";
    background-color: #d1603d;
    height: 92vh;
}
.leftSideReact,
.leftSideJS,
.leftSideCSS,
.leftSideDesign,
.leftSideASP,
.leftSideTS,
.leftSideTW,
.leftSideC,
.leftSideHTML,
.leftSideKR,
.leftSidePY,
.leftSideSDLC {
    position: relative;
    top: 1.5vw;
    font-family: "Unica One", sans-serif;
    font-size: 1.5vw;
}
.leftSideReactDiv,
.leftSideJSDiv,
.leftSideCSSDiv,
.leftSideDesignDiv,
.leftSideASPDiv,
.leftSideTSDiv,
.leftSideTWDiv,
.leftSideCDiv,
.leftSideHTMLDiv,
.leftSideKRDiv,
.leftSidePYDiv,
.leftSideSDLCDiv {
    position: relative;
    top: 2vw;
    right: 2vw;
    background-color: white;
    border-radius: 2vw;
    box-shadow: 12px 12px 2px 1px rgba(0, 0, 255, 0.2);
}
.leftSideReactDiv {
    grid-area: leftSideReactDiv;
}
.leftSideJSDiv {
    grid-area: leftSideJSDiv;
    width: 10vw;
}
.leftSideCSSDiv {
    grid-area: leftSideCSSDiv;
    width: 6vw;
}
.leftSideDesignDiv {
    grid-area: leftSideDesignDiv;
}
.leftSideASPDiv {
    grid-area: leftSideASPDiv;
}
.leftSideTSDiv {
    grid-area: leftSideTSDiv;
}
.leftSideTWDiv {
    grid-area: leftSideTWDiv;
    width: 9vw;
}
.leftSideCDiv {
    grid-area: leftSideCDiv;
    width: 6vw;
    position: relative;
    left: 0.5vw;
}
.leftSideHTMLDiv {
    grid-area: leftSideHTMLDiv;
    width: 6vw;
}
.leftSideKRDiv {
    grid-area: leftSideKRDiv;
}
.leftSidePYDiv {
    grid-area: leftSidePYDiv;
    z-index: 10;
}
.leftSideSDLCDiv {
    grid-area: leftSideSDLCDiv;
}
.leftSkillTab {
    grid-area: lst;
    background-color: #acafec;
}
.newLeftSideSecondPage {
    display: grid;
    grid-area: newLeftSideSecondPage;
    grid-template-rows: 0.1fr 0.3fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr;
    grid-template-columns: 0.3fr 0.3fr 0.3fr 0.3fr 0.3fr 0.3fr;
    grid-template-areas:
        "lst lst lst lst lst lst lst"
        ". . myLeftSkillsHeading myLeftSkillsHeading . . ."
        ". leftSideReactDiv leftSideJSDiv leftSideCSSDiv leftSideDesignDiv . ."
        ". leftSideASPDiv leftSideASPDiv leftSideTSDiv leftSideTSDiv . ."
        ". leftSideTWDiv leftSideCDiv leftSideHTMLDiv leftSideKRDiv . ."
        ". leftSidePYDiv leftSidePYDiv leftSideSDLCDiv leftSideSDLCDiv . .";
    height: 80vh;
    background-color: #cbd4c2;
    width: 49.5vw;
}
.myLeftSkillsHeading {
    grid-area: myLeftSkillsHeading;
    font-family: "Unica One", sans-serif;
    color: black;
    position: relative;
    font-size: 2.5vw;
    top: 3vw;
}
.myRightSkillsHeading {
    grid-area: myRightSkillsHeading;
    font-family: "Unica One", sans-serif;
    color: white;
    text-align: center;
    position: relative;
    top: 3vw;
    font-size: 2.5vw;
}
.topLeftSoftSkill {
    grid-area: topLeftSoftSkill;
}
.topRightSoftSkill {
    grid-area: topRightSoftSkill;
}
.bottomLeftSoftSkill {
    grid-area: bottomLeftSoftSkill;
}
.bottomRightSoftSkill {
    grid-area: bottomRightSoftSkill;
}
.topLeftSoftSkill,
.topRightSoftSkill,
.bottomLeftSoftSkill,
.bottomRightSoftSkill {
    text-align: center;
    font-family: "Unica One", sans-serif;
    color: white;
    font-size: 1.5vw;
}
.softSkillText {
    font-family: "Unica One", sans-serif;
    font-size: 1.2vw;
    position: relative;
    top: 2vw;
}
.rightSideTab {
    grid-area: rst;
    background-color: #d88c9a;
}
.newRightSideSecondPage {
    display: grid;
    grid-area: newRightSecondPage;
    grid-template-rows: 0.2fr 0.5fr 1fr 1fr 0.2fr;
    grid-template-areas:
        "rst rst"
        "myRightSkillsHeading myRightSkillsHeading"
        "topLeftSoftSkill topRightSoftSkill"
        "bottomRightSoftSkill bottomLeftSoftSkill"
        ". .";
    height: 80vh;
    background-color: #2e4057;
    width: 49.56vw;
}
.newThirdPageHeader {
    grid-area: newThirdPageHeader;
    font-family: "Unica One", sans-serif;
    font-size: 3vw;
    text-align: center;
    color: white;
    position: relative;
    top: 5vw;
}
.newTextActivities1,
.newTextActivities2,
.newTextActivities3,
.newTextActivities4 {
    /* writing-mode: vertical-rl;
    text-orientation: upright; */
    font-family: "Unica One", sans-serif;
    font-size: 6vw;
    color: white;
    transform: rotate(-90deg);
    position: absolute;
    margin-top: 10vw;
}
.newTextActivities1 {
    grid-area: newTextActivities1;
}
.newPActivities1 {
    grid-area: newPActivities1;
    opacity: 0;
    font-family: "Unica One", sans-serif;
    font-size: 2vw;
    color: white;
    text-align: center;
}
.newPActivities1:hover {
    visibility: visible;
    transition: opacity 0.001s ease;
}
.newFirstActivity:hover .newPActivities1 {
    opacity: 1;
}
.newFirstActivity:hover {
    width: 50vw;
    height: 37.1vw;
}
.newFirstActivity,
.newSecondActivity,
.newThirdActivity,
.newFourthActivity {
    position: relative;
    overflow: hidden; /* Hide overflow during hover */
    width: 15vw; /* Fixed width */
    transition: all 0.3s ease-in-out; /* Ensure a smooth hover transition */
    top: 8vw;
    height: 40.1vw;
}
.newFirstActivity:hover,
.newSecondActivity:hover,
.newThirdActivity:hover,
.newFourthActivity:hover {
    position: relative;
    top: 8vw;
}
.newTextActivities3 {
    grid-area: newTextActivities3;
}
.newTextActivities4 {
    grid-area: newTextActivities4;
}
/* When hovering over the .newFirstActivity, show the image */
.newFirstActivity:hover .newPAImage1 {
    opacity: 1; /* Make the image visible */
}
.newFirstActivity:hover .newPAImage2 {
    opacity: 1; /* Make the image visible */
}
.newFirstActivity:hover .newPAImage3 {
    opacity: 1; /* Make the image visible */
}
.newFirstActivity:hover .newPAImage4 {
    opacity: 1; /* Make the image visible */
}
.newFirstActivity:hover .newPAImage5 {
    opacity: 1; /* Make the image visible */
}
.newFirstActivity:hover .newPAImage6 {
    opacity: 1; /* Make the image visible */
}
.newPAImage1 {
    grid-area: newPAImage1;
    height: 10vw;
    position: relative;
    z-index: 3;
    top: 0vw;
    left: 3vw;
    opacity: 0; /* Hide the image */
    transition: opacity 0.3s ease; /* Smooth transition when showing the image */
}
.newPAImage2 {
    grid-area: newPAImage2;
    height: 10vw;
    z-index: 4;
    opacity: 0; /* Hide the image */
    transition: opacity 0.3s ease; /* Smooth transition when showing the image */
}
.newPAImage3 {
    grid-area: newPAImage3;
    height: 10vw;
    position: relative;
    bottom: 4vw;
    left: 5vw;
    z-index: 2;
    opacity: 0; /* Hide the image */
    transition: opacity 0.3s ease; /* Smooth transition when showing the image */
}
.newPAImage4 {
    grid-area: newPAImage4;
    z-index: 6;
    opacity: 0; /* Hide the image */
    transition: opacity 0.3s ease; /* Smooth transition when showing the image */
    position: relative;
    left: 3vw;
    bottom: 5vw;
}
.newPAImage5 {
    grid-area: newPAImage5;
    position: relative;
    height: 23vw;
    left: 5vw;
}
.newPAImage6 {
    grid-area: newPAImage6;
    height: 15.58vw;
    position: relative;
    left: 12vw;
    z-index: 1;
}
.newFirstActivity {
    display: grid;
    grid-area: newFirstActivity;
    grid-template-columns: 0.4fr 1fr 1fr;
    grid-template-rows: 0.3fr 1fr 1fr;
    grid-template-areas:
        ". newPActivities1 newPActivities1"
        ". newPAImage1 newPAImage2"
        ". newPAImage4 newPAImage3";
    background-color: #473144;
    border-radius: 10vw 0 0 0;
    width: 15vw;
    transition: width 0.3s ease;
    height: 36.6vw;
}
.newTextActivities2 {
    grid-area: newTextActivities2;
}
.newSecondActivity:hover .newPActivities2 {
    opacity: 1;
}
.newSecondActivity:hover .newPActivities22 {
    opacity: 1;
}
.newPActivities2 {
    grid-area: newPActivities2;
    margin: 0vw 2vw 0vw 2vw;
    position: relative;
    top: 2vw;
}
.newPActivities22 {
    grid-area: newPActivities22;
    margin: 0vw 2vw 0vw 2vw;
    position: relative;
    top: 4vw;
}
.newPActivities2,
.newPActivities22 {
    opacity: 0;
    font-family: "Unica One", sans-serif;
    font-size: 2vw;
    color: white;
    text-align: center;
}
.newPActivities2:hover,
.newPActivities22:hover {
    visibility: visible;
    transition: opacity 0.001s ease;
}
.newSecondActivity {
    display: grid;
    grid-area: newSecondActivity;
    grid-template-columns: 0.4fr 1fr 1fr;
    grid-template-rows: 0.5fr 0.5fr 1fr 1fr;
    grid-template-areas:
        ". newPActivities2 newPActivities2"
        ". newPActivities22 newPActivities22"
        ". newPAImage5 ."
        ". . .";
    grid-template-columns: 0.3fr 1fr;

    background-color: #70a288;
    border-radius: 10vw 0 0 0;
    width: 15vw;
    transition: width 0.3s ease;
    height: 36.5vw;
}
.newSecondActivity:hover {
    width: 50vw;
    height: 37.1vw;
}
.newThirdActivity:hover .newPActivities3 {
    opacity: 1;
}
.newThirdActivity:hover .videoukelele {
    visibility: visible;
}
.newPActivities3 {
    grid-area: newPActivities3;
    opacity: 0;
    font-family: "Unica One", sans-serif;
    font-size: 2vw;
    color: white;
    text-align: center;
    margin: 0vw 3vw 0vw 3vw;
    position: relative;
    top: 2vw;
}
.newPActivities3:hover {
    visibility: visible;
    transition: opacity 0.001s ease;
}
.videoukelele {
    grid-area: videoukelele;
    position: relative;
    bottom: 6vw;
    height: 5vw;
    visibility: hidden;
}
.newThirdActivity {
    display: grid;
    grid-area: newThirdActivity;
    grid-template-columns: 0.4fr 1fr 1fr;
    grid-template-areas:
        ". newPActivities3 newPActivities3"
        ". videoukelele videoukelele"
        ". . .";
    background-color: #5b599c;
    border-radius: 10vw 0 0 0;
    width: 15vw;
    transition: width 0.3s ease;
    height: 36.5vw;
}
.newThirdActivity:hover {
    width: 50vw;
    height: 37.1vw;
}
.newFourthActivity:hover .newPActivities4 {
    opacity: 1;
}
.newFourthActivity:hover .newPActivities44 {
    opacity: 1;
}
.newPActivities4 {
    grid-area: newPActivities4;
    opacity: 0;
    font-family: "Unica One", sans-serif;
    font-size: 2vw;
    color: white;
    text-align: center;
    margin: 0vw 3vw 0vw 3vw;
    position: relative;
    top: 2vw;
}
.newPActivities44 {
    grid-area: newPActivities44;
    opacity: 0;
    font-family: "Unica One", sans-serif;
    font-size: 2vw;
    color: white;
    text-align: center;
    margin: 0vw 3vw 0vw 3vw;
    position: relative;
    top: 4vw;
    z-index: 2;
}
.newPActivities4:hover {
    visibility: visible;
    transition: opacity 0.001s ease;
}
.newPActivities44:hover {
    visibility: visible;
    transition: opacity 0.001s ease;
}
.newFourthActivity {
    display: grid;
    grid-area: newFourthActivity;
    grid-template-columns: 0.4fr 1fr 1fr;
    grid-template-rows: 0.5fr 0.5fr 1fr 1fr;
    grid-template-areas:
        ". newPActivities4 newPActivities4"
        ". newPActivities44  newPActivities44"
        ". newPAImage6 newPAImage6";
    background-color: #e2a3c7;
    border-radius: 10vw 0 0 0;
    width: 15vw;
    transition: width 0.3s ease;
    height: 36.5vw;
}
.newFourthActivity:hover {
    width: 50vw;
    height: 37.1vw;
}
.hovermearrow {
    grid-area: hovermearrow;
    position: relative;
    bottom: 10vw;
}
.hoverarrow {
    grid-area: hovermearrow;
    position: relative;
    top: 5vw;
}

.newAboutMeThirdPage {
    display: grid;
    grid-area: newAboutMeThirdPage;
    grid-template-rows: 0.4fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas:
        "hovermearrow newThirdPageHeader newThirdPageHeader newThirdPageHeader newThirdPageHeader ."
        ". newFirstActivity newSecondActivity newThirdActivity newFourthActivity .";
    height: 102vh;
    background-color: #d1603d;
    position: relative;
    overflow: hidden;
}

.newAboutMeSecondPage {
    display: grid;
    grid-area: newAboutMeSecondPage;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    height: 97vh;
    grid-template-areas:
        "newLeftSideSecondPage newLeftSideSecondPage newLeftSideSecondPage newRightSecondPage newRightSecondPage newRightSecondPage"
        "newAboutMeThirdPage newAboutMeThirdPage newAboutMeThirdPage newAboutMeThirdPage newAboutMeThirdPage newAboutMeThirdPage";
}
.newRightSideThirdPage {
    height: 100vh;
}
/* newcontactme css============================================================================================ */

.headingInContact {
    grid-area: headingInContact;
    font-family: "Unica One", sans-serif;
    font-size: 10vw;
    color: #550000;
    position: relative;
    top: 80px;
}
.newEmail {
    grid-area: newEmail;
    font-family: "Unica One", sans-serif;
    font-size: 3vw;
    color: #550000;
    position: relative;
    top: 10vw;
    margin-right: 4vw;
}
.newContactOr {
    grid-area: newContactOr;
    font-family: "Unica One", sans-serif;
    font-size: 5vw;
    position: relative;
    right: 3vw;
}
.firstSpotContact {
    grid-area: firstSpotContact;
}
.secondSpotContact {
    grid-area: secondSpotContact;
}
.thirdSpotContact {
    grid-area: thirdSpotContact;
}
.fourthSpotContact {
    grid-area: fourthSpotContact;
    position: relative;
    right: 1vw;
}
.newContactEmail {
    grid-area: newContactEmail;
    background-color: #d9d9d9 !important;
    border-radius: 10vw !important;
    align-items: center;
    width: 95%;
    color: rgb(66, 66, 66);
    font-family: "Courier New", Courier, monospace;
    font-size: 1.5vw !important;
    box-shadow: 12px 12px 2px 1px rgb(66, 63, 63);
    height: 5vw;
}
.newContactEmail1 {
    grid-area: newContactEmail1;
    background-color: #d9d9d9 !important;
    border-radius: 10vw !important;
    align-items: center;
    width: 98%;
    color: rgb(66, 66, 66);
    font-family: "Courier New", Courier, monospace;
    font-size: 1.5vw !important;
    box-shadow: 12px 12px 2px 1px rgb(66, 63, 63);
    height: 10vw;
}
.newContactEmail2 {
    grid-area: newContactEmail2;
    background-color: #d9d9d9 !important;
    border-radius: 10vw !important;
    align-items: center;
    width: 98%;
    color: rgb(66, 66, 66);
    font-family: "Courier New", Courier, monospace;
    font-size: 1.5vw !important;
    box-shadow: 12px 12px 2px 1px rgb(66, 63, 63);
    height: 5vw;
}
.afterResponseOfForm {
    font-family: "Unica One", sans-serif !important;
    align-items: center !important;
    width: 98%;
    color: rgb(66, 66, 66);
    font-family: "Courier New", Courier, monospace;
    font-size: 5vw !important;
    height: 10vw;
    position: relative;
    left: 2vw;
}
.newContactForm {
    display: grid;
    grid-area: newContactForm;
    grid-template-rows: 0.2fr 1fr 1fr 1fr 0.2fr;
    grid-template-columns: 0.1fr 1fr 1fr 0.1fr;
    grid-template-areas:
        ". . . ."
        ". firstSpotContact secondSpotContact ."
        ". thirdSpotContact thirdSpotContact fourthSpotContact"
        ". thirdSpotContact thirdSpotContact ."
        ". . . .";
    margin: 0vw 4vw 0vw 4vw;
    background-color: white;
    border-radius: 2vw 2vw 0vw 0vw;
}
.newContactMotherDiv {
    display: grid;
    height: 92vh;
    background-color: #d1603d;
    grid-template-columns: 1fr 0.2fr 0.7fr;
    grid-template-rows: 1fr 0.2fr 1fr;
    grid-template-areas:
        "headingInContact . newEmail"
        ". newContactOr ."
        "newContactForm newContactForm newContactForm";
}
/* new projects============================================================================================ */
.headingInProjects {
    grid-area: headingInProjects;
    font-family: "Unica One", sans-serif;
    font-size: 10vw;
    color: #550000;
    position: relative;
    top: 80px;
}
.newFirstProject,
.newSecondProject,
.newThirdProject,
.newFourthProject,
.newFifthProject,
.newSixthProject {
    background-color: white;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;
    box-shadow: 12px 12px 2px 1px rgb(26, 25, 25);
    width: 15vw;
    height: 15vw;
}
.newFirstProject {
    grid-area: newFirstProject;
    background-image: url("/public/images/easyday.png");
    position: relative;
    right: 5vw;
    top: 10vw;
}
.newSecondProject {
    grid-area: newSecondProject;
    background-image: url("/public/images/cake_resized.png");
    position: relative;
    top: 3vw;
}
.newThirdProject {
    grid-area: newThirdProject;
    background-image: url("/public/images/arduino1.jpg");
    position: relative;
    left: 1vw;
    top: 5vw;
}
.newFourthProject {
    grid-area: newFourthProject;
    background-image: url("/public/images/unitygame.png");
    position: relative;
    top: 3vw;
}
.newFifthProject {
    grid-area: newFifthProject;
    background-image: url("/public/images/client-server.png");
    position: relative;
    top: 3vw;
}
.newSixthProject {
    grid-area: newSixthProject;
    background-image: url("/public/images/flightbooking.jpg");
    position: relative;
    top: 13vw;
    left: 7vw;
}

.newProjectTextClothing,
.newProjectTextCake,
.newProjectTextEmbed,
.newProjectText2d,
.newProjectTextc2s,
.newProjectTextFlight {
    font-family: "Unica One", sans-serif;
    font-size: 2.5vw;
}
.newProjectTextClothing {
    position: relative;
    bottom: 7vw;
}
.newProjectTextCake {
    position: relative;
    top: 16vw;
}
.newProjectTextEmbed {
    position: relative;
    top: 16vw;
}
.newProjectText2d {
    position: relative;
    top: 16vw;
}
.newProjectTextc2s {
    position: relative;
    top: 16vw;
}
.newProjectTextFlight {
    position: relative;
    bottom: 4vw;
}
.newProjectsMotherDiv {
    display: grid;
    height: 93vh;
    background-color: #d1603d;
    /* background-image: url("src/images/lounge.png"); */
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 0.3fr;
    grid-template-areas:
        " headingInProjects headingInProjects . newThirdProject . newFourthProject ."
        ". newFirstProject newSecondProject . newFifthProject newSixthProject ."
        ". . . . . . .";
}
.headingInProjectsDesigns {
    grid-area: headingInProjectsDesigns;
    font-family: "Unica One", sans-serif;
    font-size: 10vw;
    color: #edd3c4;
    position: relative;
}
.firstProjDesignDiv {
    display: flex;
    justify-content: center;
    grid-area: firstProjDesignDiv;
}
.firstProjDesign {
    grid-area: firstProjDesign;
    height: 30em;
    width: auto;
}
.secondProjDesignDiv {
    display: flex;
    justify-content: center;
    grid-area: secondProjDesignDiv;
}
.secondProjDesign {
    grid-area: secondProjDesign;
    height: 30em;
    width: auto;
}
.thirdProjDesignDiv {
    display: flex;
    justify-content: center;
    grid-area: thirdProjDesignDiv;
    position: relative;
    top: 1em;
}
.thirdProjDesign {
    grid-area: thridProjDesign;
    height: 30em;
    width: auto;
}
.fourthProjDesignDiv {
    display: flex;
    justify-content: center;
    grid-area: fourthProjDesignDiv;
}
.fourthProjDesign {
    grid-area: fourthProjDesign;
    height: 30em;
    width: auto;
}
.newProjectsDesigns {
    display: grid;
    height: 100vh;
    background-color: #000000;
    grid-template-columns: 0.2fr 1fr 1fr 1fr 1fr 0.2fr;
    grid-template-rows: 0.2fr 1fr 0.2fr;
    grid-template-areas:
        "headingInProjectsDesigns headingInProjectsDesigns headingInProjectsDesigns . . ."
        ". firstProjDesignDiv secondProjDesignDiv thirdProjDesignDiv fourthProjDesignDiv ."
        ". . . . . .";
}
@media only screen and (max-width: 768px) {
    .navigations {
        left: 0;
        font-size: 12vw;
    }
    .About,
    .Contact,
    .Project,
    .Home {
        position: relative;
        left: 0;
    }

    /* aboutme mobile responsiveness================================================================================ */
    .newHomeGrid1,
    .nameInHome,
    .newInHomeText,
    .portfolioInHomeFirst,
    .portftext,
    .newPortPic1,
    .portfolioInHomeSecond,
    .liotext,
    .newAboutMeButton,
    .newCvButton,
    .newProjectsButton,
    .linkedinicon,
    .facebookicon,
    .githubicon,
    .instagramicon,
    .discordicon {
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0;
        padding: 0;
    }
    .newHomeMotherDiv {
        grid-template-columns: 0.2fr 1fr 1fr 1fr 1fr 1fr 0.2fr;
        grid-template-rows: 0.2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 0.2fr;
        grid-template-areas:
            ". . . . . . ."
            ". . newHomeGrid1 newHomeGrid1 . . ."
            ". . portfolioInHomeFirst newPortPic1 portfolioInHomeSecond . ."
            ". . newInHomeText newInHomeText newInHomeText . ."
            ". . newCVButton . . . ."
            ". . newAboutMeButton . . . ."
            ". . newProjectsButton . . . ."
            ". . linkedinicon facebookicon githubicon . ."
            ". . . . . . .";
    }
    .newAboutFirstText,
    .newAboutSecondText,
    .newJSText {
        font-size: 5vw;
        margin: 0 5vw;
        top: 2vw;
        margin-top: 14vw;
        text-align: center;
    }
    .leftSkillTab {
        opacity: 0;
    }
    .rightSideTab {
        opacity: 0;
    }
    .dogpeeking {
        height: 25vw;
        top: 7.5vw;
    }
    .catpeeking {
        height: 22vw;
        top: 9vw;
        left: -2vw;
    }
    .newJSText {
        opacity: 0;
        height: 0.01vw;
        width: 0.01vw;
    }
    .newAboutMeFirstPage {
        grid-template-rows: auto auto auto auto;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-areas:
            "newAboutFirstText newAboutFirstText newAboutFirstText"
            "newAboutSecondText newAboutSecondText newAboutSecondText"
            "dogpeeking newJSText catpeeking"
            "dogpeeking newJSText catpeeking";
        height: auto;
    }
    .newLeftSideSecondPage,
    .newRightSideSecondPage {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        height: auto;
    }
    .myLeftSkillsHeading {
        text-align: center;
    }
    .leftSideReact,
    .leftSideJS,
    .leftSideCSS,
    .leftSideDesign,
    .leftSideASP,
    .leftSideTS,
    .leftSideTW,
    .leftSideC,
    .leftSideHTML,
    .leftSideKR,
    .leftSidePY,
    .leftSideSDLC {
        font-size: 4vw;
    }
    .leftSideCDiv {
        position: relative;
        margin: 0;
    }
    .leftSideReactDiv,
    .leftSideJSDiv,
    .leftSideCSSDiv,
    .leftSideDesignDiv,
    .leftSideASPDiv,
    .leftSideTSDiv,
    .leftSideTWDiv,
    .leftSideCDiv,
    .leftSideHTMLDiv,
    .leftSideKRDiv,
    .leftSidePYDiv,
    .leftSideSDLCDiv {
        width: 100%;
    }
    .leftSideSDLCDiv,
    .leftSideHTMLDiv,
    .leftSideKRDiv {
        z-index: 10;
    }
    .newLeftSideSecondPage {
        display: grid;
        grid-template-columns: 0.2fr 1fr 1fr 0.2fr;
        grid-template-rows: 0.4fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 0.4fr;
        grid-template-areas:
            ". . . ."
            ". myLeftSkillsHeading myLeftSkillsHeading ."
            ". leftSideReactDiv leftSideJSDiv ."
            ". leftSideCSSDiv leftSideDesignDiv ."
            ". leftSideASPDiv leftSideASPDiv ."
            ". leftSideTSDiv leftSideTSDiv ."
            ". leftSideTWDiv leftSideCDiv ."
            ". leftSideHTMLDiv leftSideKRDiv ."
            ". leftSidePYDiv leftSidePYDiv ."
            ". leftSideSDLCDiv leftSideSDLCDiv ."
            ". . . .";
        width: 50.5vw;
    }

    .newLeftSideSecondPage {
        height: 105vw;
    }

    .myLeftSkillsHeading,
    .myRightSkillsHeading {
        font-size: 5vw;
        top: 1vw;
    }

    .newFirstActivity,
    .newSecondActivity,
    .newThirdActivity,
    .newFourthActivity {
        width: 100vw;
        height: auto;
        border-radius: 5vw;
        top: 5vw;
    }

    .newThirdPageHeader {
        font-size: 6vw;
        top: 1vw;
    }
    .softSkillHeadings {
        position: relative;
        top: 2vw;
        font-size: 4vw;
        margin-top: 0.5vw;
    }
    .softSkillText {
        font-size: 2.5vw;
    }

    .newRightSideSecondPage {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: 0.2fr 0.6fr 1fr 1fr 1fr 1fr 0.2fr;
        grid-template-areas:
            ". . . ."
            "myRightSkillsHeading myRightSkillsHeading myRightSkillsHeading myRightSkillsHeading"
            "topLeftSoftSkill topLeftSoftSkill topLeftSoftSkill topLeftSoftSkill"
            "topRightSoftSkill topRightSoftSkill topRightSoftSkill topRightSoftSkill"
            "bottomLeftSoftSkill bottomLeftSoftSkill bottomLeftSoftSkill bottomLeftSoftSkill"
            "bottomRightSoftSkill bottomRightSoftSkill bottomRightSoftSkill bottomRightSoftSkill"
            ". . . .";
    }
    .newAboutMeThirdPage {
        grid-template-columns: 1fr;
        grid-template-rows: 0.4fr 1fr 1fr 1fr 1fr 1fr 0.4fr;
        grid-template-areas:
            "."
            "newThirdPageHeader"
            "newFirstActivity"
            "newSecondActivity"
            "newThirdActivity"
            "newFourthActivity"
            ".";
        height: 100vh;
        position: relative;
    }
    .newFirstActivity,
    .newSecondActivity,
    .newThirdActivity,
    .newFourthActivity {
        height: 40vw;
        pointer-events: none;
    }
    .newTextActivities1 {
        font-size: 8vw;
        top: 7vw;
    }
    .newTextActivities2,
    .newTextActivities4 {
        font-size: 8vw;
        top: 8vw;
        left: -4vw;
    }
    .newTextActivities3 {
        top: 5vw;
        font-size: 8vw;
        left: -3vw;
    }
    .newPActivities1 {
        grid-area: newPActivities1;
        opacity: 1;
        font-family: "Unica One", sans-serif;
        font-size: 3vw;
        color: white;
        text-align: center;
    }
    .newPActivities2,
    .newPActivities22,
    .newPActivities3,
    .newPActivities4,
    .newPActivities44 {
        opacity: 1;
        font-family: "Unica One", sans-serif;
        font-size: 3vw;
        color: white;
        text-align: center;
    }
    .newPAImage1,
    .newPAImage2,
    .newPAImage3,
    .newPAImage4 {
        opacity: 1;
    }
    .newPAImage5 {
        position: relative;
        left: 25vw;
        top: 5vw;
    }
    .newPAImage6 {
        position: relative;
        left: 33vw;
        top: 7vw;
    }
    .newPAImage3 {
        position: relative;
        left: 20vw;
    }
    .newPAImage4 {
        height: 12vw;
        left: 20vw;
    }
    .videoukelele {
        position: relative;
        visibility: visible;
        height: 28vw !important;
        width: 28vw !important;
        object-fit: cover;
        top: 0.5vw;
        left: 27vw;
        cursor: pointer;
    }
    .newFirstActivity:hover {
        width: auto; /* Reset to default width */
        height: auto; /* Reset to default height */
    }
    .hoverarrow {
        top: 2vw;
    }
    /* contact mobile responsiveness================================================================================ */
    .newContactEmail,
    .newContactEmail1,
    .newContactEmail2 {
        box-shadow: 6px 6px 2px 1px rgb(66, 63, 63);
        height: 12vw;
    }
    .firstSpotContact {
        margin: 0;
    }
    .newContactEmail1 {
        height: 20vw;
        font-size: 2vw;
    }
    .newContactEmail2 {
        font-size: 2vw;
    }
    .newContactForm {
        display: grid;
        grid-area: newContactForm;
        grid-template-rows: 0.2fr 1fr 1fr 1fr 1fr 1fr 0.2fr;
        grid-template-columns: 0.1fr 1fr 1fr 0.1fr;
        grid-template-areas:
            ". . . ."
            ". firstSpotContact firstSpotContact ."
            ". secondSpotContact secondSpotContact ."
            ". thirdSpotContact thirdSpotContact ."
            ". thirdSpotContact thirdSpotContact ."
            ". fourthSpotContact fourthSpotContact ."
            ". . . .";
        margin: 0vw 4vw 0vw 4vw;
        background-color: white;
        border-radius: 2vw 2vw 0vw 0vw;
    }
    .headingInContact {
        font-size: 12vw;
        text-align: center;
        margin: 0;
        left: -2vw;
        top: 16vw;
    }
    .newContactOr {
        grid-area: newContactOr;
        font-family: "Unica One", sans-serif;
        font-size: 8vw;
        position: relative;
        right: 5vw;
    }
    .newEmail {
        font-size: 6vw;
    }
    h1.newEmail,
    p.newEmail {
        position: relative;
        text-align: center;
    }
    input::placeholder {
        font-size: 12px;
        color: #888;
    }
    .submitButton {
        font-size: 5vw;
    }
    .newContactMotherDiv {
        display: grid;
        height: 92vh;
        background-color: #d1603d;
        grid-template-columns: 1fr 0.2fr 0.7fr;
        grid-template-rows: 0.2fr 0.2fr 0.1fr 0.6fr;
        grid-template-areas:
            "headingInContact headingInContact headingInContact"
            "newEmail newEmail newEmail"
            ". newContactOr ."
            "newContactForm newContactForm newContactForm";
    }
    /* projects mobile responsive================================= */
    .headingInProjects {
        text-align: center;
        font-size: 12vw;
        right: 7vw;
        top: 7vw;
    }
    .newFirstProject,
    .newSecondProject,
    .newThirdProject,
    .newFourthProject,
    .newFifthProject,
    .newSixthProject {
        width: 30vw;
        height: 30vw;
        border-radius: 50%;
        box-shadow: 8px 8px 1px 1px rgb(26, 25, 25);
    }
    .newFirstProject,
    .newSecondProject,
    .newThirdProject,
    .newFourthProject,
    .newFifthProject,
    .newSixthProject {
        top: -5vw;
        right: 0;
        left: 8.5vw;
        bottom: 0;
    }

    .newProjectTextClothing,
    .newProjectTextCake,
    .newProjectTextEmbed,
    .newProjectText2d,
    .newProjectTextc2s,
    .newProjectTextFlight {
        font-size: 4vw;
    }

    .newProjectTextClothing,
    .newProjectTextFlight,
    .newProjectTextCake,
    .newProjectTextEmbed,
    .newProjectText2d,
    .newProjectTextc2s {
        top: -9vw;
    }
    .newProjectTextEmbed,
    .newProjectTextCake {
        top: -12vw;
    }

    .newProjectsMotherDiv {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 0.2fr 1fr 1fr 1fr 1fr 0.2fr;
        grid-template-areas:
            ". ."
            "headingInProjects headingInProjects"
            "newThirdProject newSecondProject"
            "newFirstProject newFourthProject"
            "newFifthProject newSixthProject"
            ". .";
        height: 100vh;
    }
}
@media (max-width: 768px) {
    .newProjectsDesigns {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        grid-template-areas:
            "headingInProjectsDesigns"
            "firstProjDesignDiv"
            "secondProjDesignDiv"
            "thirdProjDesignDiv"
            "fourthProjDesignDiv";
    }

    .firstProjDesignDiv,
    .secondProjDesignDiv,
    .thirdProjDesignDiv,
    .fourthProjDesignDiv {
        justify-content: center;
    }

    .firstProjDesign,
    .secondProjDesign,
    .thirdProjDesign,
    .fourthProjDesign {
        height: auto;
        width: 90%; /* Adjust width for better scaling */
    }
}

@media (max-width: 480px) {
    .newProjectsDesigns {
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: auto;
        grid-template-areas:
            ". headingInProjectsDesigns ."
            "firstProjDesignDiv firstProjDesignDiv firstProjDesignDiv"
            "secondProjDesignDiv secondProjDesignDiv secondProjDesignDiv"
            "thirdProjDesignDiv thirdProjDesignDiv thirdProjDesignDiv"
            "fourthProjDesignDiv fourthProjDesignDiv fourthProjDesignDiv";
    }

    .firstProjDesign,
    .secondProjDesign,
    .thirdProjDesign,
    .fourthProjDesign {
        height: auto;
        width: 100%; /* Full width for smaller screens */
    }
}
/* General Navigation Styles */

/* Responsive Styles for Mobile */
@media (max-width: 768px) {
    .newNav {
        background-color: #edd3c4;
        position: relative;
        z-index: 100;
    }

    .newHomeLink,
    .newAboutLink,
    .newContactLink,
    .newProjectsLink {
        font-family: "Unica One", sans-serif;
        font-size: 2em;
    }

    .newLogo {
        position: relative;
        grid-area: newLogo;
        height: 4em;
        width: auto;
        top: 1em;
    }

    /* Hamburger Icon */
    .hamburger-menu {
        display: none; /* Hidden by default */
        flex-direction: column;
        justify-content: space-between;
        position: absolute; /* Position it at the far right */
        top: 1rem; /* Adjust vertical alignment */
        right: 1rem; /* Position it to the far right */
        width: 25px;
        height: 20px;
        cursor: pointer;
    }

    .hamburger-menu .bar {
        width: 100%;
        height: 3px;
        background-color: black;
        transition: all 0.3s ease;
    }

    .hamburger-menu:hover .bar {
        background-color: #555;
    }

    /* Mobile Navigation Links */
    .navigation-links {
        display: none; /* Hidden by default */
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #edd3c4;
        position: fixed; /* Cover the entire screen */
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 10;
        padding: 1rem;
        transition: all 0.3s ease;
    }

    .navigation-links.open {
        display: flex;
    }

    /* Navigation Links Styling */
    .navigation-links ul {
        list-style: none;
        padding: 0;
        margin: 1rem 0;
    }

    .navigation-links li {
        margin: 1rem 0;
    }

    .navigation-links a {
        color: black;
        text-decoration: none;
        font-size: 1.5rem;
    }
    .hamburger-menu {
        display: flex; /* Visible only on mobile */
    }

    .navigation-links {
        display: none; /* Hidden by default */
    }

    .navigation-links.open {
        display: flex; /* Show when menu is toggled */
    }
}

/* Desktop View */
@media (min-width: 769px) {
    .hamburger-menu {
        display: none; /* Hide hamburger on desktop */
    }

    .navigation-links {
        display: flex; /* Desktop links always visible */
        flex-direction: row;
        position: static;
        background-color: transparent;
        width: auto;
    }
}
